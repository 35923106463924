import React, { useState, useEffect } from 'react';

const ImageGuessingGame = ({ images, gridSize }) => {
  const [correctImages, setCorrectImages] = useState({});
  const [gameOver, setGameOver] = useState(false);
  // Use a set to keep track of indices of images that have already been guessed correctly.
  const [guessedIndices, setGuessedIndices] = useState(new Set());
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Filter the shuffledOrder to exclude guessed images.
  const shuffledOrder = [...Array(images.length).keys()]
    .sort(() => Math.random() - 0.5)
    .filter(index => !guessedIndices.has(index));

  useEffect(() => {
    // Update the currentImageIndex to the first in the filtered shuffledOrder.
    if (shuffledOrder.length > 0) {
      setCurrentImageIndex(shuffledOrder[0]);
    }
  }, [guessedIndices]); // Depend on guessedIndices so it updates when guessedIndices change.

  useEffect(() => {
    if (Object.keys(correctImages).length === images.length) {
      setGameOver(true);
    }
  }, [correctImages, images.length]);

  // Handle box click
  const handleBoxClick = (boxIndex) => {
    if (boxIndex === currentImageIndex) {
      setCorrectImages({ ...correctImages, [boxIndex]: images[boxIndex].src });
      // Add the index to guessedIndices to exclude it from future selections.
      setGuessedIndices(new Set(guessedIndices).add(boxIndex));

      // No need to manually set the next currentImageIndex here as it's handled by the useEffect.
    } else {
      alert('Try again!');
    }
  };

  // Generate the grid boxes
  const renderGrid = () => {
    return images.map((image, index) => (
      <div key={index} className="w-24 h-32 bg-red-200 m-2 flex flex-col justify-center items-center cursor-pointer rounded-lg border-2 border-red-800">
        <div className={`box w-full h-24 flex justify-center items-center ${correctImages[index] ? '' : 'bg-red-200'} rounded-md`}
             onClick={() => handleBoxClick(index)}>
          {correctImages[index] && <img src={correctImages[index]} alt="Correct" className="max-w-full max-h-full rounded-md" />}
        </div>
        {/* Format the date to "Month day", e.g., "January 1" */}
        <div className="date-label text-xs text-black">
          {image.date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}
        </div>
      </div>
    ));
  };
  

  // Reset the game
  const resetGame = () => {
    setCorrectImages({});
    setGameOver(false);
    setGuessedIndices(new Set());
    // No need to reset currentImageIndex as it will be set by the useEffect hook.
  };

  return (
    <div className="game-container flex flex-wrap justify-center max-w-6xl mx-auto my-8">
      {renderGrid()}
      {!gameOver && shuffledOrder.length > 0 && (
        <div className="current-image flex flex-col items-center mb-24">
          <p className="text-lg mb-2">Match this image:</p>
          <img src={images[currentImageIndex]?.src} alt="To Match" className="w-24 h-24" />
        </div>
      )}
      {gameOver && (
        <div className="success-screen flex flex-row items-center mt-4 mb-24">
          <p className="text-xl mr-4">Thanks for playing Maleah! </p>
          {/* Redirect to messages app with prepopulated text */}
          <a href="sms:6692199788&body=I wanna be your valentine Akhil!" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded" style={{ textDecoration: 'none' }}>
            Click Here!
          </a>
        </div>
      )}
    </div>
  );
};

export default ImageGuessingGame;
