import React from 'react';
import './App.css';
import ImageGuessingGame from './ImageGuessingGame';

import image1 from './photos/photo1.jpeg';
import image2 from './photos/photo2.jpeg';
import image3 from './photos/photo3.jpeg';
import image4 from './photos/photo4.JPG';
import image5 from './photos/photo5.JPG';
import image6 from './photos/photo6.JPG';
import image7 from './photos/photo7.JPG';
import image8 from './photos/photo8.JPG';
import image9 from './photos/photo9.JPG';
import image10 from './photos/photo10.JPG';
import image11 from './photos/photo11.JPG';
import image12 from './photos/photo12.JPG';
import image13 from './photos/photo13.JPG';
import image14 from './photos/photo14.JPG';
import image15 from './photos/photo15.JPG';
import image16 from './photos/photo16.JPG';
import image17 from './photos/photo17.JPG';
import image18 from './photos/photo18.JPG';
import image19 from './photos/photo19.JPG';
import image20 from './photos/photo20.JPG';
import image21 from './photos/photo21.JPG';
import image22 from './photos/photo22.JPG';
import image23 from './photos/photo23.JPG';


const images = [
  { src: image1, date: new Date(2023, 7, 30) }, 
  { src: image2, date: new Date(2023, 9, 9) },
  { src: image3, date: new Date(2023, 9, 10) }, 
  { src: image4, date: new Date(2023, 9, 11) },
  { src: image5, date: new Date(2023, 9, 13) },
  { src: image6, date: new Date(2023, 9, 28) },
  { src: image7, date: new Date(2023, 9, 30) },
  { src: image8, date: new Date(2023, 10, 10) },
  { src: image9, date: new Date(2023, 10, 17) },
  { src: image10, date: new Date(2023, 10, 26) },
  { src: image11, date: new Date(2023, 10, 30) },
  { src: image12, date: new Date(2023, 11, 3) },
  { src: image13, date: new Date(2023, 11, 8) },
  { src: image14, date: new Date(2024, 0, 12) },
  { src: image15, date: new Date(2024, 0, 13) },
  { src: image16, date: new Date(2024, 0, 14) },
  { src: image17, date: new Date(2024, 0, 25) },
  { src: image18, date: new Date(2024, 0, 29) },
  { src: image19, date: new Date(2024, 0, 31) },
  { src: image20, date: new Date(2024, 1, 4) },
  { src: image21, date: new Date(2024, 1, 5) },
  { src: image22, date: new Date(2024, 1, 6) },
  { src: image23, date: new Date(2024, 1, 7) },

];

function App() {
  return (
    <div className="h-screen text-white bg-black">
      <h1 className="py-4 text-2xl font-bold text-center text-white">
        Wanna be my Valentine Maleah?
      </h1>
      <ImageGuessingGame images={images} gridSize={16} />
    </div>
  );
}

export default App;
